html {
  box-sizing: border-box;

  & *, & *:before, & *:after {
    box-sizing: inherit;
  }
}


body {
  background: #B30C59;
  font-family: 'Montserrat', sans-serif;
  min-width: 320px;
}


/* Pseudo Element Breakpoints for JS */
body::before {
  display: none;
  content: "small";

  @media (--medium) {
    content: "medium";
  }

  @media (--large) {
    content: "large";
  }
}

main {
  margin: 0 auto;
  max-width: var(--site-max-width);
  position: relative;
z-index: 100;
}

img {
  display: inline-block;
  max-width: 100%;
  height: auto;
  vertical-align: middle;
}

ul, li, p {
  margin: 0;
  padding: 0;
  list-style: none;
  font-size: var(--font-size-primary);
  line-height: var(--global-line-height);
}

.logo {
  margin: 20px 0;
  text-align: right;
  padding-right: 10%;

  @media (--medium) {
    text-align: center;
  }

  @media (--large-up) {
    margin: 40px 0;
    padding-left: 20px;
    text-align: left;
    background: url(../assets/img/circus.png),url(../assets/img/circus.png),url(../assets/img/circus.png);
    background-repeat: no-repeat, no-repeat,no-repeat;
    background-size: auto 70px;
    background-position: 98% top, 88% top, 78% top;
  }

  & img {
    max-width: 180px;

    @media (--large-up) {
      max-width: 240px;
    }
  }
}

hgroup h1 {
  margin: 40px 0 6px 0;
  color: #4E214C;
  font-size: 26px;
  font-family: 'Merriweather', serif;
  line-height: 1.4;

  @media (--large-up) {
    font-size: 40px;
  }
}

hgroup h2 {
  margin: 0;
  color: #B30C59;
  text-transform: uppercase;
  font-size: 16px;
  font-family: 'Montserrat', sans-serif;
  line-height: 1.4;


  @media (--large-up) {
    font-size: 24px;
  }
}

a.material {
  display: block;

  @media (--large-up) {
    display: inline;
  }
}
