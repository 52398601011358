.navigation {

  & .fa-bars {
    position: absolute;
    top: 15px;
    left: 5%;
    color: white;
    font-size: 50px;
    cursor: pointer;

    @media (--large-up) {
      display: none;
    }
  }


  & ul  {
    display: none;
    overflow: hidden;
    margin-bottom: 30px;
    background: #54C0EB;
    @media (--large-up) {
      display: block;
      margin-bottom: 0;
    }
  }

  & li {
    display: block;
    @media (--large-up) {
      float: left;
    }
  }

  & a {
    display:block;
    padding: 15px 20px;
    border-bottom: 1px solid white;
    color: white;
    text-align: center;
    text-decoration: none;
    font-size: 19px;
    line-height: 1.5;

    @media (--large-up) {
      border-right: 1px solid rgba(255,255,255,.3);
      border-bottom: 0;
    }

    &:hover,
    &.current,
    &#active {
      background: #B2DB1D;
    }
  }
}
