/* import *********************************/
@import "settings.css";
@import "normalize.css";
@import "grid.css";
@import "global.css";

/* thypo *********************************/
@import "../fonts/font-awesome/css/font-awesome.css";
@import url('https://fonts.googleapis.com/css?family=Merriweather|Montserrat');

/*font-family: 'Montserrat', sans-serif;
font-family: 'Merriweather', serif;*/

/* blocks *********************************/
@import "blocks/stars.css";
@import "blocks/main.css";
@import "blocks/content.css";
@import "blocks/navigation.css";

/* libs *********************************/
@import "libs/slick.css";
