.main {
  & .main__text {
    margin: 20px 0;
    font-size: 16px;
    & [target="_blank"]::before {
      display: inline-block;
      margin-right: 5px;
      content: "\f08e";
      font: var(--icon-font);
    }
  }
  & .main__list--style li {
    margin-left: 20px;
    list-style: disc;
    & a {
      color: #b30c59;
      text-decoration: none;
    }
  }
  & .main__teaser {
    margin-top: 40px;
    font-size: 16px;
    & li {
      padding-bottom: 10px;
      text-align: center;
    }
    & a {
      display: inline-block;
      margin: 20px 0;
      padding: 15px;
      background: #a3144e;
      color: #fcfefc;
      text-decoration: none;
      font-size: 18px;
    }
  }
  & .main__footer {
    margin-bottom: 20px;
    padding: 30px;
    background: #46264F;
    color: white;
    & li {
      padding-right: 2%;
    }
    & a {
      color: #8bc4e4;
      text-decoration: none;
    }
  }

  & .main__share {
    position: fixed;
    right: 25px;
    bottom: 25px;
    z-index: 50;
    width: 70px;
    height: 70px;
    display: none;
  }
}
