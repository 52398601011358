.slick-slider
{
    position: relative;

    display: block;

    -moz-box-sizing: border-box;
         box-sizing: border-box;

    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;

    -webkit-touch-callout: none;
    -khtml-user-select: none;
    -ms-touch-action: pan-y;
        touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;
}

.slick-list
{
    position: relative;

    display: block;
    overflow: hidden;

    margin: 0;
    padding: 0;
}
.slick-list:focus
{
    outline: none;
}
.slick-list.dragging
{
    cursor: pointer;
    cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list
{
    -webkit-transform: translate3d(0, 0, 0);
       -moz-transform: translate3d(0, 0, 0);
        -ms-transform: translate3d(0, 0, 0);
         -o-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
}

.slick-track
{
    position: relative;
    top: 0;
    left: 0;
    display: flex;
}
.slick-track:before,
.slick-track:after
{
    display: table;

    content: '';
}
.slick-track:after
{
    clear: both;
}
.slick-loading .slick-track
{
    visibility: hidden;
}

.slick-slide
{
    display: none;
}
[dir='rtl'] .slick-slide
{
    float: right;
}
.slick-slide img
{
    display: inline-block;
}
.slick-slide img[hidden]
{
    display: none;
    @media (--small) {
      display: block;
    }
}
.slick-slide.slick-loading img
{
    display: none;
}
.slick-slide.dragging img
{
    pointer-events: none;
}
.slick-initialized .slick-slide
{
    display: flex;
    flex-direction: column;
}

.slick-slide.js_productbox {
    flex-direction: row !important;
}

.slick-slide.payment-info {
    flex-direction: row !important;
    justify-content: center;
}

.slick-loading .slick-slide
{
    visibility: hidden;
}

.slick-vertical .slick-track
{
  display: block;
}
.slick-arrow.slick-hidden {
    display: none;
}

/* Arrows */
.slick-prev,
.slick-next
{
    font-size: 0;
    line-height: 0;

    position: absolute;
    top:45%;

    display: block;
    margin-top: -10px\9; /*lte IE 8*/
    -webkit-transform: translate(0, -50%);
    -ms-transform: translate(0, -50%);
    transform: translate(0, -50%);

    cursor: pointer;

    color: transparent;
    border: none;
    outline: none;
    background: transparent;
    z-index: 100;
}

.slick-vertical .slick-prev {
  top: -50px;
  left: 40%;
  transform: rotate(90deg);
}
.slick-vertical .slick-next {
  top: auto;
  left: 40%;
  bottom: -45px;
  transform: rotate(90deg);
}

.slick-prev:hover,
.slick-prev:focus,
.slick-next:hover,
.slick-next:focus
{
    color: transparent;
    outline: none;
    background: transparent;
}
.slick-prev:hover:before,
.slick-prev:focus:before,
.slick-next:hover:before,
.slick-next:focus:before
{
    opacity: 1;
}
.slick-prev.slick-disabled:before,
.slick-next.slick-disabled:before
{
    opacity: .25;
}

.slick-prev:before,
.slick-next:before
{
    display: block;
    font: var(--icon-font);
    font-size: 32px;
    line-height: 1;
    color: #777;

    @media (--medium-up) {
      font-size: 55px;
    }
}

.slick-prev:after {
  content: "\f104";
  left: 7px;
}

.slick-next:after {
  content: "\f105";
  right: 7px;
}

.slick-prev:after,
.slick-next:after
{
    display: block;
    font: var(--icon-font);
    font-size: 32px;
    line-height: 1;
    color: white;
    position: absolute;
    top: 1px;
    z-index: 2;
    @media (--medium-up) {
      font-size: 55px;
    }
}

.slick-prev
{
    left: 0;


}
[dir='rtl'] .slick-prev
{
    right: -25px;
    left: auto;
}
.slick-prev:before
{
    content: "\f104";
}
[dir='rtl'] .slick-prev:before
{
    content: '→';
}

.slick-next
{
    right: 0;
}
[dir='rtl'] .slick-next
{
    right: auto;
    left: -25px;
}
.slick-next:before
{
    content: "\f105";
}
[dir='rtl'] .slick-next:before
{
    content: '←';
}

/* Dots */
.slick-slider
{
    /*margin-bottom: 30px;*/
}

.slick-dots
{
    position: absolute;
    bottom: -37px;

    display: block;

    width: 100%;
    padding: 0;

    list-style: none;

    text-align: center;
}
.slick-dots li
{
    position: relative;

    display: inline-block;
    width: 20px;
    height: 20px;
    padding: 0;

    cursor: pointer;
}
.slick-dots li button
{
    font-size: 0;
    line-height: 0;

    display: block;

    width: 20px;
    height: 20px;
    padding: 5px;

    cursor: pointer;

    color: transparent;
    border: 0;
    outline: none;
    background: transparent;
}
.slick-dots li button:hover,
.slick-dots li button:focus
{
    outline: none;
}
.slick-dots li button:hover:before,
.slick-dots li button:focus:before
{
    opacity: 1;
}
.slick-dots li button:before
{
    font-family: 'slick';
    font-size: 20px;
    line-height: 20px;

    position: absolute;
    top: 0;
    left: 0;

    width: 10px;
    height: 10px;
    background: var(--color-dark);
    border-radius: 100%;
    content: '';
    text-align: center;

    opacity: .25;
    color: black;

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
.slick-dots li.slick-active button:before
{
    opacity: .75;
    color: black;
}


_:-ms-fullscreen, :root .slick-initialized .slick-slide:not(.product__item) {
  display: block !important;
}
.is__safari .slick-initialized .slick-slide {
  display: block !important;
}
