.grid-row {
    max-width: var(--site-max-width);
    margin-right: auto;
    margin-left: auto;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
}
.grid-row .grid-row {
  margin: 0 calc( var(--grid-space) - ( var(--grid-space) * 2 ) );
}

.grid-row.expanded {
    max-width: none;
}
.grid-row.collapse > .grid-column,
.grid-row.collapse > .grid-columns {
    padding-right: 0;
    padding-left: 0;
}
.grid-row.is-collapse-child,
.grid-row.collapse > .grid-column > .grid-row,
.grid-row.collapse > .grid-columns > .grid-row {
    margin-right: 0;
    margin-left: 0;
}
.grid-column,
.grid-columns {
    -ms-flex: 1 1 0px;
    flex: 1 1 0px;
    padding-left: var(--grid-space, 15px);
    padding-right: var(--grid-space, 15px);
    /*min-width: initial;*/
    min-width: 0;
    min-height: 0;
}
.grid-column.grid-row.grid-row,
.grid-row.grid-row.grid-columns {
    display: -ms-flexbox;
    display: flex;
}
.grid-row .grid-column.grid-row.grid-row,
.grid-row .grid-row.grid-row.grid-columns {
    margin-right: 0;
    margin-left: 0;
    padding-right: 0;
    padding-left: 0;
}
.flex-container {
    display: -ms-flexbox;
    display: flex;
}
.flex-child-auto {
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
}
.flex-child-grow {
    -ms-flex: 1 0 auto;
    flex: 1 0 auto;
}
.flex-child-shrink {
    -ms-flex: 0 1 auto;
    flex: 0 1 auto;
}
.flex-dir-row {
    -ms-flex-direction: row;
    flex-direction: row;
}
.flex-dir-row-reverse {
    -ms-flex-direction: row-reverse;
    flex-direction: row-reverse;
}
.flex-dir-column {
    -ms-flex-direction: column;
    flex-direction: column;
}
.flex-dir-column-reverse {
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse;
}
.small-1 {
    -ms-flex: 0 0 8.33333%;
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
}
.small-offset-0 {
    margin-left: 0%;
}
.small-2 {
    -ms-flex: 0 0 16.66667%;
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
}
.small-offset-1 {
    margin-left: 8.33333%;
}
.small-3 {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
}
.small-offset-2 {
    margin-left: 16.66667%;
}
.small-4 {
    -ms-flex: 0 0 33.33333%;
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
}
.small-offset-3 {
    margin-left: 25%;
}
.small-5 {
    -ms-flex: 0 0 41.66667%;
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
}
.small-offset-4 {
    margin-left: 33.33333%;
}
.small-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
}
.small-offset-5 {
    margin-left: 41.66667%;
}
.small-7 {
    -ms-flex: 0 0 58.33333%;
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
}
.small-offset-6 {
    margin-left: 50%;
}
.small-8 {
    -ms-flex: 0 0 66.66667%;
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
}
.small-offset-7 {
    margin-left: 58.33333%;
}
.small-9 {
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
}
.small-offset-8 {
    margin-left: 66.66667%;
}
.small-10 {
    -ms-flex: 0 0 83.33333%;
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
}
.small-offset-9 {
    margin-left: 75%;
}
.small-11 {
    -ms-flex: 0 0 91.66667%;
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
}
.small-offset-10 {
    margin-left: 83.33333%;
}
.small-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
}
.small-offset-11 {
    margin-left: 91.66667%;
}
.small-order-1 {
    -ms-flex-order: 1;
    order: 1;
}
.small-order-2 {
    -ms-flex-order: 2;
    order: 2;
}
.small-order-3 {
    -ms-flex-order: 3;
    order: 3;
}
.small-order-4 {
    -ms-flex-order: 4;
    order: 4;
}
.small-order-5 {
    -ms-flex-order: 5;
    order: 5;
}
.small-order-6 {
    -ms-flex-order: 6;
    order: 6;
}
.small-up-1 {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}
.small-up-1 > .grid-column,
.small-up-1 > .grid-columns {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
}
.small-up-2 {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}
.small-up-2 > .grid-column,
.small-up-2 > .grid-columns {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
}
.small-up-3 {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}
.small-up-3 > .grid-column,
.small-up-3 > .grid-columns {
    -ms-flex: 0 0 33.33333%;
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
}
.small-up-4 {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}
.small-up-4 > .grid-column,
.small-up-4 > .grid-columns {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
}
.small-up-5 {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}
.small-up-5 > .grid-column,
.small-up-5 > .grid-columns {
    -ms-flex: 0 0 20%;
    flex: 0 0 20%;
    max-width: 20%;
}
.small-up-6 {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}
.small-up-6 > .grid-column,
.small-up-6 > .grid-columns {
    -ms-flex: 0 0 16.66667%;
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
}
.small-up-7 {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}
.small-up-7 > .grid-column,
.small-up-7 > .grid-columns {
    -ms-flex: 0 0 14.28571%;
    flex: 0 0 14.28571%;
    max-width: 14.28571%;
}
.small-up-8 {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}
.small-up-8 > .grid-column,
.small-up-8 > .grid-columns {
    -ms-flex: 0 0 12.5%;
    flex: 0 0 12.5%;
    max-width: 12.5%;
}
.small-collapse > .grid-column,
.small-collapse > .grid-columns {
    padding-right: 0;
    padding-left: 0;
}
.small-uncollapse > .grid-column,
.small-uncollapse > .grid-columns {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
}
@media print,
screen and (min-width: 481px) {
    .medium-1 {
        -ms-flex: 0 0 8.33333%;
        flex: 0 0 8.33333%;
        max-width: 8.33333%;
    }
    .medium-offset-0 {
        margin-left: 0%;
    }
    .medium-2 {
        -ms-flex: 0 0 16.66667%;
        flex: 0 0 16.66667%;
        max-width: 16.66667%;
    }
    .medium-offset-1 {
        margin-left: 8.33333%;
    }
    .medium-3 {
        -ms-flex: 0 0 25%;
        flex: 0 0 25%;
        max-width: 25%;
    }
    .medium-offset-2 {
        margin-left: 16.66667%;
    }
    .medium-4 {
        -ms-flex: 0 0 33.33333%;
        flex: 0 0 33.33333%;
        max-width: 33.33333%;
    }
    .medium-offset-3 {
        margin-left: 25%;
    }
    .medium-5 {
        -ms-flex: 0 0 41.66667%;
        flex: 0 0 41.66667%;
        max-width: 41.66667%;
    }
    .medium-offset-4 {
        margin-left: 33.33333%;
    }
    .medium-6 {
        -ms-flex: 0 0 50%;
        flex: 0 0 50%;
        max-width: 50%;
    }
    .medium-offset-5 {
        margin-left: 41.66667%;
    }
    .medium-7 {
        -ms-flex: 0 0 58.33333%;
        flex: 0 0 58.33333%;
        max-width: 58.33333%;
    }
    .medium-offset-6 {
        margin-left: 50%;
    }
    .medium-8 {
        -ms-flex: 0 0 66.66667%;
        flex: 0 0 66.66667%;
        max-width: 66.66667%;
    }
    .medium-offset-7 {
        margin-left: 58.33333%;
    }
    .medium-9 {
        -ms-flex: 0 0 75%;
        flex: 0 0 75%;
        max-width: 75%;
    }
    .medium-offset-8 {
        margin-left: 66.66667%;
    }
    .medium-10 {
        -ms-flex: 0 0 83.33333%;
        flex: 0 0 83.33333%;
        max-width: 83.33333%;
    }
    .medium-offset-9 {
        margin-left: 75%;
    }
    .medium-11 {
        -ms-flex: 0 0 91.66667%;
        flex: 0 0 91.66667%;
        max-width: 91.66667%;
    }
    .medium-offset-10 {
        margin-left: 83.33333%;
    }
    .medium-12 {
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%;
    }
    .medium-offset-11 {
        margin-left: 91.66667%;
    }
    .medium-order-1 {
        -ms-flex-order: 1;
        order: 1;
    }
    .medium-order-2 {
        -ms-flex-order: 2;
        order: 2;
    }
    .medium-order-3 {
        -ms-flex-order: 3;
        order: 3;
    }
    .medium-order-4 {
        -ms-flex-order: 4;
        order: 4;
    }
    .medium-order-5 {
        -ms-flex-order: 5;
        order: 5;
    }
    .medium-order-6 {
        -ms-flex-order: 6;
        order: 6;
    }
    .medium-up-1 {
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    }
    .medium-up-1 > .grid-column,
    .medium-up-1 > .grid-columns {
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%;
    }
    .medium-up-2 {
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    }
    .medium-up-2 > .grid-column,
    .medium-up-2 > .grid-columns {
        -ms-flex: 0 0 50%;
        flex: 0 0 50%;
        max-width: 50%;
    }
    .medium-up-3 {
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    }
    .medium-up-3 > .grid-column,
    .medium-up-3 > .grid-columns {
        -ms-flex: 0 0 33.33333%;
        flex: 0 0 33.33333%;
        max-width: 33.33333%;
    }
    .medium-up-4 {
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    }
    .medium-up-4 > .grid-column,
    .medium-up-4 > .grid-columns {
        -ms-flex: 0 0 25%;
        flex: 0 0 25%;
        max-width: 25%;
    }
    .medium-up-5 {
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    }
    .medium-up-5 > .grid-column,
    .medium-up-5 > .grid-columns {
        -ms-flex: 0 0 20%;
        flex: 0 0 20%;
        max-width: 20%;
    }
    .medium-up-6 {
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    }
    .medium-up-6 > .grid-column,
    .medium-up-6 > .grid-columns {
        -ms-flex: 0 0 16.66667%;
        flex: 0 0 16.66667%;
        max-width: 16.66667%;
    }
    .medium-up-7 {
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    }
    .medium-up-7 > .grid-column,
    .medium-up-7 > .grid-columns {
        -ms-flex: 0 0 14.28571%;
        flex: 0 0 14.28571%;
        max-width: 14.28571%;
    }
    .medium-up-8 {
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    }
    .medium-up-8 > .grid-column,
    .medium-up-8 > .grid-columns {
        -ms-flex: 0 0 12.5%;
        flex: 0 0 12.5%;
        max-width: 12.5%;
    }
}
@media print,
screen and (min-width: 481px) and (min-width: 481px) {
    .medium-expand {
        -ms-flex: 1 1 0px;
        flex: 1 1 0px;
    }
}
@media print,
screen and (min-width: 481px) {
    .medium-flex-dir-row {
        -ms-flex-direction: row;
        flex-direction: row;
    }
    .medium-flex-dir-row-reverse {
        -ms-flex-direction: row-reverse;
        flex-direction: row-reverse;
    }
    .medium-flex-dir-column {
        -ms-flex-direction: column;
        flex-direction: column;
    }
    .medium-flex-dir-column-reverse {
        -ms-flex-direction: column-reverse;
        flex-direction: column-reverse;
    }
    .medium-flex-child-auto {
        -ms-flex: 1 1 auto;
        flex: 1 1 auto;
    }
    .medium-flex-child-grow {
        -ms-flex: 1 0 auto;
        flex: 1 0 auto;
    }
    .medium-flex-child-shrink {
        -ms-flex: 0 1 auto;
        flex: 0 1 auto;
    }
}
.grid-row.medium-unstack > .grid-column,
.grid-row.medium-unstack > .grid-columns {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
}
@media print,
screen and (min-width: 481px) {
    .grid-row.medium-unstack > .grid-column,
    .grid-row.medium-unstack > .grid-columns {
        -ms-flex: 1 1 0px;
        flex: 1 1 0px;
    }
}
@media print,
screen and (min-width: 481px) {
    .medium-collapse > .grid-column,
    .medium-collapse > .grid-columns {
        padding-right: 0;
        padding-left: 0;
    }
    .medium-uncollapse > .grid-column,
    .medium-uncollapse > .grid-columns {
        padding-right: 0.9375rem;
        padding-left: 0.9375rem;
    }
}
@media print,
screen and (min-width: 781px) {
    .large-1 {
        -ms-flex: 0 0 8.33333%;
        flex: 0 0 8.33333%;
        max-width: 8.33333%;
    }
    .large-offset-0 {
        margin-left: 0%;
    }
    .large-2 {
        -ms-flex: 0 0 16.66667%;
        flex: 0 0 16.66667%;
        max-width: 16.66667%;
    }
    .large-offset-1 {
        margin-left: 8.33333%;
    }
    .large-3 {
        -ms-flex: 0 0 25%;
        flex: 0 0 25%;
        max-width: 25%;
    }
    .large-offset-2 {
        margin-left: 16.66667%;
    }
    .large-4 {
        -ms-flex: 0 0 33.33333%;
        flex: 0 0 33.33333%;
        max-width: 33.33333%;
    }
    .large-offset-3 {
        margin-left: 25%;
    }
    .large-5 {
        -ms-flex: 0 0 41.66667%;
        flex: 0 0 41.66667%;
        max-width: 41.66667%;
    }
    .large-offset-4 {
        margin-left: 33.33333%;
    }
    .large-6 {
        -ms-flex: 0 0 50%;
        flex: 0 0 50%;
        max-width: 50%;
    }
    .large-offset-5 {
        margin-left: 41.66667%;
    }
    .large-7 {
        -ms-flex: 0 0 58.33333%;
        flex: 0 0 58.33333%;
        max-width: 58.33333%;
    }
    .large-offset-6 {
        margin-left: 50%;
    }
    .large-8 {
        -ms-flex: 0 0 66.66667%;
        flex: 0 0 66.66667%;
        max-width: 66.66667%;
    }
    .large-offset-7 {
        margin-left: 58.33333%;
    }
    .large-9 {
        -ms-flex: 0 0 75%;
        flex: 0 0 75%;
        max-width: 75%;
    }
    .large-offset-8 {
        margin-left: 66.66667%;
    }
    .large-10 {
        -ms-flex: 0 0 83.33333%;
        flex: 0 0 83.33333%;
        max-width: 83.33333%;
    }
    .large-offset-9 {
        margin-left: 75%;
    }
    .large-11 {
        -ms-flex: 0 0 91.66667%;
        flex: 0 0 91.66667%;
        max-width: 91.66667%;
    }
    .large-offset-10 {
        margin-left: 83.33333%;
    }
    .large-12 {
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%;
    }
    .large-offset-11 {
        margin-left: 91.66667%;
    }
    .large-order-1 {
        -ms-flex-order: 1;
        order: 1;
    }
    .large-order-2 {
        -ms-flex-order: 2;
        order: 2;
    }
    .large-order-3 {
        -ms-flex-order: 3;
        order: 3;
    }
    .large-order-4 {
        -ms-flex-order: 4;
        order: 4;
    }
    .large-order-5 {
        -ms-flex-order: 5;
        order: 5;
    }
    .large-order-6 {
        -ms-flex-order: 6;
        order: 6;
    }
    .large-up-1 {
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    }
    .large-up-1 > .grid-column,
    .large-up-1 > .grid-columns {
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%;
    }
    .large-up-2 {
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    }
    .large-up-2 > .grid-column,
    .large-up-2 > .grid-columns {
        -ms-flex: 0 0 50%;
        flex: 0 0 50%;
        max-width: 50%;
    }
    .large-up-3 {
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    }
    .large-up-3 > .grid-column,
    .large-up-3 > .grid-columns {
        -ms-flex: 0 0 33.33333%;
        flex: 0 0 33.33333%;
        max-width: 33.33333%;
    }
    .large-up-4 {
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    }
    .large-up-4 > .grid-column,
    .large-up-4 > .grid-columns {
        -ms-flex: 0 0 25%;
        flex: 0 0 25%;
        max-width: 25%;
    }
    .large-up-5 {
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    }
    .large-up-5 > .grid-column,
    .large-up-5 > .grid-columns {
        -ms-flex: 0 0 20%;
        flex: 0 0 20%;
        max-width: 20%;
    }
    .large-up-6 {
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    }
    .large-up-6 > .grid-column,
    .large-up-6 > .grid-columns {
        -ms-flex: 0 0 16.66667%;
        flex: 0 0 16.66667%;
        max-width: 16.66667%;
    }
    .large-up-7 {
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    }
    .large-up-7 > .grid-column,
    .large-up-7 > .grid-columns {
        -ms-flex: 0 0 14.28571%;
        flex: 0 0 14.28571%;
        max-width: 14.28571%;
    }
    .large-up-8 {
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    }
    .large-up-8 > .grid-column,
    .large-up-8 > .grid-columns {
        -ms-flex: 0 0 12.5%;
        flex: 0 0 12.5%;
        max-width: 12.5%;
    }
}
@media print,
screen and (min-width: 781px) and (min-width: 781px) {
    .large-expand {
        -ms-flex: 1 1 0px;
        flex: 1 1 0px;
    }
}
@media print,
screen and (min-width: 781px) {
    .large-flex-dir-row {
        -ms-flex-direction: row;
        flex-direction: row;
    }
    .large-flex-dir-row-reverse {
        -ms-flex-direction: row-reverse;
        flex-direction: row-reverse;
    }
    .large-flex-dir-column {
        -ms-flex-direction: column;
        flex-direction: column;
    }
    .large-flex-dir-column-reverse {
        -ms-flex-direction: column-reverse;
        flex-direction: column-reverse;
    }
    .large-flex-child-auto {
        -ms-flex: 1 1 auto;
        flex: 1 1 auto;
    }
    .large-flex-child-grow {
        -ms-flex: 1 0 auto;
        flex: 1 0 auto;
    }
    .large-flex-child-shrink {
        -ms-flex: 0 1 auto;
        flex: 0 1 auto;
    }
}
.grid-row.large-unstack > .grid-column,
.grid-row.large-unstack > .grid-columns {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
}
@media print,
screen and (min-width: 781px) {
    .grid-row.large-unstack > .grid-column,
    .grid-row.large-unstack > .grid-columns {
        -ms-flex: 1 1 0px;
        flex: 1 1 0px;
    }
}
@media print,
screen and (min-width: 781px) {
    .large-collapse > .grid-column,
    .large-collapse > .grid-columns {
        padding-right: 0;
        padding-left: 0;
    }
    .large-uncollapse > .grid-column,
    .large-uncollapse > .grid-columns {
        padding-right: 0.9375rem;
        padding-left: 0.9375rem;
    }
}
.shrink {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    max-width: 100%;
}
.align-right {
    -ms-flex-pack: end;
    justify-content: flex-end;
}
.align-center {
    -ms-flex-pack: center;
    justify-content: center;
}
.align-justify {
    -ms-flex-pack: justify;
    justify-content: space-between;
}
.align-spaced {
    -ms-flex-pack: distribute;
    justify-content: space-around;
}
.align-top {
    -ms-flex-align: start;
    align-items: flex-start;
}
.align-self-top {
    -ms-flex-item-align: start;
    align-self: flex-start;
}
.align-bottom {
    -ms-flex-align: end;
    align-items: flex-end;
}
.align-self-bottom {
    -ms-flex-item-align: end;
    align-self: flex-end;
}
.align-middle {
    -ms-flex-align: center;
    align-items: center;
}
.align-self-middle {
    -ms-flex-item-align: center;
    -ms-grid-row-align: center;
    align-self: center;
}
.align-stretch {
    -ms-flex-align: stretch;
    align-items: stretch;
}
.align-self-stretch {
    -ms-flex-item-align: stretch;
    -ms-grid-row-align: stretch;
    align-self: stretch;
}
.small-order-1 {
    -ms-flex-order: 1;
    order: 1;
}
.small-order-2 {
    -ms-flex-order: 2;
    order: 2;
}
.small-order-3 {
    -ms-flex-order: 3;
    order: 3;
}
.small-order-4 {
    -ms-flex-order: 4;
    order: 4;
}
.small-order-5 {
    -ms-flex-order: 5;
    order: 5;
}
.small-order-6 {
    -ms-flex-order: 6;
    order: 6;
}
@media print,
screen and (min-width: 481px) {
    .medium-order-1 {
        -ms-flex-order: 1;
        order: 1;
    }
    .medium-order-2 {
        -ms-flex-order: 2;
        order: 2;
    }
    .medium-order-3 {
        -ms-flex-order: 3;
        order: 3;
    }
    .medium-order-4 {
        -ms-flex-order: 4;
        order: 4;
    }
    .medium-order-5 {
        -ms-flex-order: 5;
        order: 5;
    }
    .medium-order-6 {
        -ms-flex-order: 6;
        order: 6;
    }
}
@media print,
screen and (min-width: 781px) {
    .large-order-1 {
        -ms-flex-order: 1;
        order: 1;
    }
    .large-order-2 {
        -ms-flex-order: 2;
        order: 2;
    }
    .large-order-3 {
        -ms-flex-order: 3;
        order: 3;
    }
    .large-order-4 {
        -ms-flex-order: 4;
        order: 4;
    }
    .large-order-5 {
        -ms-flex-order: 5;
        order: 5;
    }
    .large-order-6 {
        -ms-flex-order: 6;
        order: 6;
    }
}

/* Block Grid */

[class*="block-grid-"] {
  display: flex;
  flex-wrap: wrap;
  padding: 0;
  margin: 0 calc( var(--block-grid-space) - (var(--block-grid-space)*2) );
}

[class*="block-grid-"] > li {
  display: block;
  padding: 0 var(--block-grid-space);
}

/* Normal Block Grid */
@media only screen {

  .small-block-grid-1 > li {
    list-style: none;
    flex: 0 0 100%;
    max-width: 100%;
  }

  .small-block-grid-2 > li {
    list-style: none;
    flex: 0 0 50%;
    max-width: 50%;
  }

  .small-block-grid-3 > li {
    list-style: none;
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }

  .small-block-grid-4 > li {
    list-style: none;
    flex: 0 0 25%;
    max-width: 25%;
  }

  .small-block-grid-5 > li {
    list-style: none;
    flex: 0 0 20%;
    max-width: 20%;
  }

  .small-block-grid-6 > li {
    list-style: none;
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }

  .small-block-grid-7 > li {
    list-style: none;
    flex: 0 0 14.28571%;
    max-width: 14.28571%;
  }

  .small-block-grid-8 > li {
    list-style: none;
    flex: 0 0 12.5%;
    max-width: 12.5%;
  }

  .small-block-grid-9 > li {
    list-style: none;
    flex: 0 0 11.11111%;
    max-width: 11.11111%;
  }

  .small-block-grid-10 > li {
    list-style: none;
    flex: 0 0 10%;
    max-width: 10%;
  }

  .small-block-grid-11 > li {
    list-style: none;
    flex: 0 0 9.09091%;
    max-width: 9.09091%;
  }

  .small-block-grid-12 > li {
    list-style: none;
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }
}

  /* Medium Block Grid */
@media (--medium) {

  .medium-block-grid-1 > li {
    list-style: none;
    flex: 0 0 100%;
    max-width: 100%;
  }

  .medium-block-grid-2 > li {
    list-style: none;
    flex: 0 0 50%;
    max-width: 50%;
  }

  .medium-block-grid-3 > li {
    list-style: none;
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }

  .medium-block-grid-4 > li {
    list-style: none;
    flex: 0 0 25%;
    max-width: 25%;
  }

  .medium-block-grid-5 > li {
    list-style: none;
    flex: 0 0 20%;
    max-width: 20%;
  }

  .medium-block-grid-6 > li {
    list-style: none;
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }

  .medium-block-grid-7 > li {
    list-style: none;
    flex: 0 0 14.28571%;
    max-width: 14.28571%;
  }

  .medium-block-grid-8 > li {
    list-style: none;
    flex: 0 0 12.5%;
    max-width: 12.5%;
  }

  .medium-block-grid-9 > li {
    list-style: none;
    flex: 0 0 11.11111%;
    max-width: 11.11111%;
  }

  .medium-block-grid-10 > li {
    list-style: none;
    flex: 0 0 10%;
    max-width: 10%;
  }

  .medium-block-grid-11 > li {
    list-style: none;
    flex: 0 0 9.09091%;
    max-width: 9.09091%;
  }

  .medium-block-grid-12 > li {
    list-style: none;
    width: 8.33333%;
    max-width: 8.33333%;
  }

}

  /* Large Block Grid */
@media (--large) {

  .large-block-grid-1 > li {
    list-style: none;
    flex: 0 0 100%;
    max-width: 100%;
  }

  .large-block-grid-2 > li {
    list-style: none;
    width: 50%;
    max-width: 50%;
  }

  .large-block-grid-3 > li {
    list-style: none;
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }

  .large-block-grid-4 > li {
    list-style: none;
    flex: 0 0 25%;
    max-width: 25%;
  }

  .large-block-grid-5 > li {
    list-style: none;
    flex: 0 0 20%;
    max-width: 20%;
  }

  .large-block-grid-6 > li {
    list-style: none;
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }

  .large-block-grid-7 > li {
    list-style: none;
    flex: 0 0 14.28571%;
    max-width: 14.28571%;
  }

  .large-block-grid-8 > li {
    list-style: none;
    flex: 0 0 12.5%;
    max-width: 12.5%;
  }

  .large-block-grid-9 > li {
    list-style: none;
    flex: 0 0 11.11111%;
    max-width: 11.11111%;
  }

  .large-block-grid-10 > li {
    list-style: none;
    flex: 0 0 10%;
    max-width: 10%;
  }

  .large-block-grid-11 > li {
    list-style: none;
    flex: 0 0 9.09091%;
    max-width: 9.09091%;
  }

  .large-block-grid-12 > li {
    list-style: none;
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }

}
